import React from "react"
import { Link } from "gatsby"
import { Github } from "react-bootstrap-icons"

import PageWrapper from "../components/PageWrapper"
import Section from "../components/Section"
import Container from "../components/Container"
import SectionTitle from "../components/SectionTitle"
import SectionText from "../components/SectionText"
import Button from "../components/Button"
import Input from "../components/forms/Input"
import ButtonOutline from "../components/ButtonOutline"
import GoogleLogo from "../images/svg/google.svg"

const SignIn = () => {
  return (
    <>
      <PageWrapper
        footerConfig={{
          hasMiddleSocial: true,
          hasBottomSubscription: true,
          bottomClassName: "bg-primary-25 ",
          className: "!bg-primary-50",
        }}
      >
        <div>
          <Section isHero className="relative overflow-hidden">
            <Container>
              {/* section-wrap */}
              <div className="bg-primary-25 flex flex-col gap-14 shadow-lg tablet:px-28  px-5 py-8 tablet:py-14 rounded-3xl max-w-[612px] mx-auto">
                {/* top-part */}
                <div className="">
                  <SectionTitle className="text-center ">Sign in</SectionTitle>
                  <SectionText className="!pb-0 text-center">
                    Complete the form to get started.
                  </SectionText>
                </div>

                {/* middle-part */}
                <div className="">
                  <div className="flex flex-col gap-8">
                    <form className="flex flex-col gap-6">
                      <Input
                        type="email"
                        label="Email"
                        placeholder="johndoe@example.com"
                        name="email"
                      />
                      <Input
                        type="password"
                        label="Password"
                        placeholder="********"
                        name="password"
                      />
                      <div className="flex items-center justify-between">
                        <Input
                          type="checkbox"
                          id="Checkbox3"
                          name="checkbox"
                          label={<>Remember me</>}
                        />
                        <Link to="/reset-password">
                          <p className="font-medium text-primary-600 text-body-md">
                            Forget password?
                          </p>
                        </Link>
                      </div>
                      <Button type="submit" size={"xl"}>
                        Sign in
                      </Button>
                    </form>
                    <p className="text-center text-body-md text-neutral-500">
                      Don’t have an account?{" "}
                      <Link to="/sign-up">
                        <span className="font-medium text-primary-600">
                          Create a free account
                        </span>
                      </Link>
                    </p>
                  </div>
                </div>
                {/* bottom-part */}
                <div className="flex flex-col gap-3">
                  <Link to="#">
                    <ButtonOutline
                      size={"xl"}
                      icon={<GoogleLogo />}
                      iconRight={false}
                      className="w-full"
                      iconClass="!text-display-xs"
                    >
                      Continue with Google
                    </ButtonOutline>
                  </Link>
                  <Link to="#">
                    <ButtonOutline
                      size={"xl"}
                      icon={<Github />}
                      iconRight={false}
                      className="w-full"
                      iconClass="!text-display-xs"
                    >
                      Continue with Github
                    </ButtonOutline>
                  </Link>
                  <div className=""></div>
                </div>
              </div>
              <div className="absolute left-0 top-[20%] -z-20">
                <div
                  data-aos="fade-down-right"
                  className="w-[258px]  bg-success-50 before:block before: content-[''] pb-[258px]"
                >
                  <div className="absolute w-[110px] bg-warning-50 before:block before:content-[''] pb-[110px] left-full top-full"></div>
                </div>
              </div>
              <div className="absolute bottom-0 right-0 translate-x-1/2 -z-10">
                <div
                  data-aos="fade-up-left"
                  className=" w-[400px] bg-neutral-50  before:block before:content-[''] pb-[400px] rounded-full"
                ></div>
              </div>
            </Container>
          </Section>
        </div>
      </PageWrapper>
    </>
  )
}

export default SignIn
